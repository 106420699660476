//bootstrap css
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.visible-xs {
    display: none;
}
.hidden-xs {
    display: block;
}
@media screen and (max-width:$size-xs-max){
    .hidden-xs {
        display: none;
    }
    .visible-xs {
        display: block;
    }
}

.hidden-mobile {
    display: block;
}
.hidden-desktop {
    display: none;
}

@media screen and (max-width: $size-sm-max) {
    .hidden-mobile {
        display: none !important;
    }
    .hidden-desktop {
        display: block;
    }
}
