.btn {
    background-color: #223e7b;
    color: $color-white;
    border: none;
    border-radius: $round-corner-radius;
    padding: 15px 60px;
    font-size: $font-size-24;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
        font-size: $font-size-24;
        color: $color-black;
    }
}
