section.section-fiche {
    div.container-fluid.row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;

        div.image-side {
            width: 34%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            & > div {
                overflow: hidden;
                img {
                  transition: .5s;
                }
                &:hover {
                    img {
                      transform: scale(1.2);
                      transition: .5s
                    }
                }
            }


            div.main-image, div.main-image img {
                width: 100%;
            }
            div.thumb {
                width: 33%;
                margin: 3px 0;
            }

            @media screen and (max-width: $size-xs-max){
                order: -1;
                width: 100%;
                transition: .5s;
                img {
                  margin: auto;
                }
            }

        }

        div.text-side {
            padding: 5%;
            width: 66%;
            transition: .5s;

            h3 {
                color: $color-black;
                font-size: $font-size-36;
            }

            p {
                line-height: 1.5;
                font-size: $font-size-20;
                padding-right: 30px;
            }

            ul {
                padding-left: 20px;
                li {
                    line-height: 1.5;
                    font-size: $font-size-22;
                }
            }

            div.box-description {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
                div.description {
                  width: 100%;
                  padding-bottom: 30px;
                }
            }

            @media screen and (max-width: $size-xs-max){
                text-align: center;
                width: 100%;
                transition: .5s;
                div.box-description {
                    padding-left: 30px;
                    padding-bottom: 30px;
                    align-items: center;
                    justify-content: center;
                    p {
                        text-align: center;
                    }
                }
            }
        }
    }
}
