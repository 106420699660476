@import '../../vendors/alertify/themes/alertify.core';
@import '../../vendors/alertify/themes/alertify.bootstrap';

.alertify-logs {
    top: 50px;
    left: 50%;
    width: 550px;
    margin-left: -275px;
}

.alertify-log {
    text-align: center;
    padding: 30px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.3), 0px 0px 20px rgba(0,0,0,0.3);
}
.alertify-inner {
    background: rgba(218, 255, 228, 0.27);
    .alertify-message {
        color: #05b305;
        text-align: center;
        font-weight: 400;
    }
}

.alertify-button-ok {
    background-color: #01a904;
}

.alertify-cover {
    background-color: rgba(2, 2, 2, 0.761)!important;
    filter: alpha(opacity=1)!important;
    opacity: 1!important;
}