// this make the template FONTS responsive if used with "rem" units
html {
    font-size: $font-size-base-lg; // used for rem units
}

@media screen and (max-width: $size-md-max) {
    html {
        font-size: $font-size-base-md;
    }
}

@media screen and (max-width: $size-sm-max) {
    html {
        font-size: $font-size-base-sm;
    }
}

@media screen and (max-width: $size-xs-max) {
    html {
        font-size: $font-size-base-xs;
    }
}