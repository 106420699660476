// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';

/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';
@import 'sections/section-fiche';
/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* FOOTER + PAGE SECONDAIRE
*******************************************************************************/

footer {
  background: url("../images/accueil_footer_bg.jpg") no-repeat;
  background-size: cover;
  height: 492px;
  position: relative;

  #blocFooter {
    margin-left: 17%;
    padding-top: 8%;
    width: 75%;
    z-index: 1;
    .menu {
      border-bottom: 2px solid #c4c3c4;
      padding-bottom: 55px;
      display: flex;
      justify-content: flex-end;
      a {
        font-weight: 600;
        font-size: $font-size-20;
        color: #fff;
        text-transform: uppercase;
        padding-right: 50px;
        &:last-child {
          padding-right: 0;
        }
        &:hover {
          color: #9c9c9c;
        }
      }
    }
    .section-copyright {
      display: flex;
      padding-top: 55px;
      justify-content: flex-end;
      a {
        font-size: $font-size-14;
        font-weight: normal;
        color: #fff;
        &:hover {
          color: #28539b;
        }
        img {
          margin-top: -5px;
          padding-right: 5px;
        }
      }
      div.copyright, span {
        font-size: $font-size-14;
        font-weight: normal;
        color: #fff;
      }
      .copyright, .mediaweb {
        padding-right: 10%;
      }
      .blocLogo {
        a.lienIcon {
          img.img-responsive.hover {
            display: none;
          }
          :hover {
            img.img-responsive.hover {
              display: block;
            }
          img.img-responsive.defaut {
              display: none;
            }
          }
        }
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 928px;
    height: 276px;
    background: url("../images/accueil_footer_triangle.png");
  }
}

footer.remplissage {
  background: url("../images/accueil_footer_bg.jpg") no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
  z-index: -1;

  #blocFooter {
    margin-left: 17%;
    padding-top: 8%;
    width: 75%;
    z-index: 1;
    .menu {
      border-bottom: 2px solid #c4c3c4;
      padding-bottom: 55px;
      display: flex;
      justify-content: flex-end;
      a {
        font-weight: 600;
        font-size: $font-size-20;
        color: #fff;
        text-transform: uppercase;
        padding-right: 50px;
        &:last-child {
          padding-right: 0;
        }
        &:hover {
          color: #9c9c9c;
        }
      }
    }
    .section-copyright {
      display: flex;
      padding-top: 55px;
      justify-content: flex-end;
      a {
        font-size: $font-size-14;
        font-weight: normal;
        color: #fff;
        &:hover {
          color: #28539b;
        }
        img {
          margin-top: -5px;
          padding-right: 5px;
        }
      }
      div.copyright, span {
        font-size: $font-size-14;
        font-weight: normal;
        color: #fff;
      }
      .copyright, .mediaweb {
        padding-right: 10%;
      }
      .blocLogo {
        a.lienIcon {
          img.img-responsive.hover {
            display: none;
          }
          :hover {
            img.img-responsive.hover {
              display: block;
            }
          img.img-responsive.defaut {
              display: none;
            }
          }
        }
      }
    }
  }
  &::before {
    display: none;
  }
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#bloc50-50_accueil {
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    background-size: contain;
    display: flex;
    position: relative;
    padding: 100px 5% 50px 8%;

    .left-side {
        width: 48%;
        padding-top: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: #000;
            padding-bottom: 5px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #000;
            text-align: justify;
            padding: 25px 0 50px 0;
            width: 90%;
            line-height: 1.7;
        }
        a.btn-accueil {
          background: #223e7b;
          padding: 15px 50px;
          color: #fff;
          text-transform: uppercase;
          font-size: $font-size-24;
          transition: 0.3s;
          &:hover {
            background: #bab9ba;
            transition: 0.3s;
          }
        }
    }

    .right-side {
        width: 45%;
        margin-left: 125px;
        margin-top: -115px;
        .main-content {
            position: relative;
            .owl-theme {
                .owl-item {
                    img {
                        width: 615px;
                        height: 304px;
                    }
                }
            }
        }
    }
    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 539px;
      height: 271px;
      background: url("../images/accueil_section01_triangle.png");
    }
}

#blocBurst {
  padding: 150px 2% 100px 2%;
  background: url("../images/accueil_section02_bg.jpg") no-repeat;
  background-position: bottom;
  display: flex;
  justify-content: space-evenly;
  a.bloc.side {
    padding-top: 50px;
  }
  .bloc {
    transition: 0.3s;
    .burst {
      img.img-responsive.hover {
        display: none;
      }
    }
    .texte {
      text-align: center;
      h4 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #fff;
        text-transform: uppercase;
        padding: 25px 0 10px 0;
      }
      p {
        font-weight: normal;
        font-size: $font-size-24;
        color: #fff;
        line-height: 1.3;
      }
    }
  }
  :hover {
    transition: 0.3s;
    img.img-responsive.hover {
      display: block !important;
    }
    img.img-responsive.defaut {
      display: none;
    }
  }
}

#blocImages {
    display: flex;
    flex-wrap: wrap;
    .image {
      width: 25%;
  }
}

/*******************************************************************************
* PRODUITS / REMORQUES
*******************************************************************************/

#titre {
  position: absolute;
  top: 74%;
  right: 9%;
  h2 {
    font-size: $font-size-48;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
  }
  h3 {
    font-size: $font-size-36;
    color: #000000;
    font-weight: 600;
    text-transform: initial;
  }
}

#blocIntro {
  padding: 0 5%;
  margin-top: -125px;
  position: relative;
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    text-align: justify;
    width: 45%;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}

#bloc50-50 {
  display: flex;
  align-items: center;
  padding: 135px 5%;

  .left-side {
    width: 60%
  }
  .right-side {
    width: 52%;
    h3 {
      font-weight: bold;
      font-size: $font-size-30;
      color: #000;
      padding-bottom: 5px;
    }
    p {
      font-weight: normal;
      font-size: $font-size-24;
      color: #000;
      width: 81%;
      text-align: justify;
    }
  }
}

#blocBackground {
  background: #223e7b;
  padding: 65px 5% 10% 5%;
  clip-path: polygon(0 1%, 100% 0%, 100% 94%, 0 68%);
  margin-bottom: -75px;
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #fff;
    padding-bottom: 35px;
  }
  .bloc-liste {
    display: flex;
    .liste {
      width: 35%;
      ul {
        li {
          list-style: square;
          font-weight: normal;
          font-size: $font-size-30;
          color: #fff;
          text-transform: initial;
          line-height: 1.5;
          padding-bottom: 15px;
        }
      }
    }
  }
}

#blocImages_remorques {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 2% 10px 2%;
    .images {
      width: 25%;
  }
}
#blocImages_remorques-2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2% 100px 2%;
    .images {
      width: 25%;
  }
}

#blocBurst_2 {
  padding: 150px 2% 135px 2%;
  background: url("../images/remplissage_bg_burst.png") no-repeat;
  background-position: bottom;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: -110px;
  a.bloc.side {
    padding-top: 50px;
  }
  .bloc {
    transition: 0.3s;
    .burst {
      img.img-responsive.hover {
        display: none;
      }
    }
    .texte {
      text-align: center;
      h4 {
        font-weight: bold;
        font-size: $font-size-30;
        color: #fff;
        text-transform: uppercase;
      }
      p {
        font-weight: normal;
        font-size: $font-size-24;
        color: #fff;
        line-height: 1.3;
        padding-top: 20px;
        margin: 0;
      }
    }
  }
  :hover {
    transition: 0.3s;
    img.img-responsive.hover {
      display: block !important;
    }
    img.img-responsive.defaut {
      display: none;
    }
  }
}

/*******************************************************************************
* PRODUITS / ATTACHES REMORQUES
*******************************************************************************/

#blocIntro_attaches {
  padding: 0 5% 100px 5%;
  margin-top: -125px;
  position: relative;
  .logo {
    position: absolute;
    left: 46%;
  }
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #000;
    padding-bottom: 5px;
  }
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    text-align: justify;
    width: 45%;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}

#blocBackground_attaches {
  background: url("../images/produits-attaches_section02_bg.jpg") no-repeat;
  padding: 120px 5%;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #fff;
    padding-bottom: 10px;
  }
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #fff;
    line-height: 1.7;
  }
}

#bloc50-50_attaches {
  display: flex;
  align-items: center;
  padding: 135px 5%;

  .left-side {
    width: 40%;
    margin-right: 60px;
  }
  .right-side {
    width: 60%;
    h3 {
      font-weight: bold;
      font-size: $font-size-30;
      color: #000;
      padding-bottom: 5px;
    }
    p {
      font-weight: normal;
      font-size: $font-size-24;
      color: #000;
      width: 93%;
      text-align: justify;
    }
  }
}

#blocImages_remorques_attaches {
    display: flex;
    flex-wrap: wrap;
    padding: 35px 2% 0 2%;
    .images {
      width: 25%;
      padding-bottom: 100px;
  }
}

/*******************************************************************************
* PRODUITS / ÉQUIPEMENTS HIVERNAUX
*******************************************************************************/

#blocBackground_equipements {
  background: #223e7b url("../images/produits-equipements_section02_img01.jpg") no-repeat right;
  display: flex;
  padding: 85px 0 90px 5%;
  align-items: center;
  .titre {
    width: 40%;
  }
  .blocLogo {
      display: flex;
      align-items: center;
      .logo.first {
        margin-right: 80px;
      }
  }
  h3 {
    font-weight: 900;
    font-size: $font-size-44;
    color: #fff;
  }
}

#blocImages_remorques_equipements {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 2% 0 2%;
    .images {
      width: 25%;
      padding-bottom: 100px;
  }
}

/*******************************************************************************
* PRODUITS / RAMPES HYDRAULIQUES
*******************************************************************************/

#blocBackground_rampes {
  background: #223e7b;
  padding: 100px 5% 3% 5%;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%);
  margin-top: 5%;
  position: relative;
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #fff;
  }
  h3.last {
    padding-bottom: 25px;
  }
  ul {
    li {
      font-weight: normal;
      font-size: $font-size-24;
      color: #fff;
      line-height: 1.5;
      text-transform: initial;
      padding-bottom: 10px;
    }
  }
  .logo {
    position: absolute;
    right: 12%;
    bottom: 15%;
  }
}

#blocImages_rampes {
    display: flex;
    flex-wrap: wrap;
  .image {
    width: 33.3333%;
  }
  .images {
    border: 1px solid #000;
  }
}

/*******************************************************************************
* SERVICES / RÉPARATION ET PIÈCES
*******************************************************************************/

#bloc50-50_reparation {
  display: flex;
  align-items: center;
  padding: 100px 5%;

  .left-side {
    width: 40%;
    margin-right: 60px;
  }
  .right-side {
    width: 60%;
    h3 {
      font-weight: bold;
      font-size: $font-size-30;
      color: #000;
      padding-bottom: 5px;
    }
    p {
      font-weight: normal;
      font-size: $font-size-24;
      color: #000;
      width: 85%;
      text-align: justify;
    }
  }
}

#blocBackground_reparation {
  background: url("../images/services-reparation_section02_bg.jpg") no-repeat;
  text-align: center;
  padding: 150px 9% 270px 9%;
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #fff;
    padding-bottom: 25px;
  }
  p {
    font-weight: normal;
    font-size: $font-size-26;
    color: #fff;
    line-height: 1.7;
  }
}

#blocImages_reparation {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 2% 10px 2%;
}
#blocImages_reparation-2 {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    .images {
      width: 25%;
      padding-bottom: 75px;
  }
}

/*******************************************************************************
* SERVICES / LOCATION DE REMORQUES
*******************************************************************************/

#blocBackground_location {
  background: #223e7b;
  padding: 100px 5% 3% 5%;
  clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%);
  margin-top: 5%;
  .bloc-liste {
    display: flex;
    .liste {
      width: 35%;
    }
  }
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #fff;
    padding-bottom: 50px;
  }
  ul {
    li {
      font-weight: normal;
      font-size: $font-size-26;
      color: #fff;
      line-height: 1.5;
      padding-bottom: 10px;
    }
  }
}

#blocImages_location {
  display: flex;
  flex-wrap: wrap;
  .image {
    width: 25%;
  }
  .blocGris {
    background: #bab9ba;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-weight: normal;
      font-size: $font-size-24;
      color: #000;
      margin: -10px;
    }
    h3 {
      font-weight: bold;
      font-size: $font-size-30;
      color: #000;
    }
  }
}

/*******************************************************************************
* SERVICES / RHINO LININGS
*******************************************************************************/

#blocIntro_rhino {
  padding: 0 5% 75px 5%;
  margin-top: -125px;
  position: relative;
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #000;
    padding-bottom: 5px;
  }
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    text-align: justify;
    width: 50%;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}
#blocBackground_rhino {
  background: #223e7b;
  padding: 65px 5% 10% 5%;
  clip-path: polygon(0 1%, 100% 0%, 100% 94%, 0 68%);
  margin-bottom: -130px;
  margin-top: -7px;
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #fff;
    padding-bottom: 25px;
  }
  .bloc-liste {
    display: flex;
    .liste {
      width: 50%;
      ul {
        padding-left: 30px;
        li {
          list-style: square;
          font-weight: normal;
          font-size: $font-size-30;
          color: #fff;
          text-transform: initial;
          line-height: 1.5;
          padding-bottom: 15px;
        }
      }
    }
  }
}

#blocImages_rhino {
  display: flex;
  flex-wrap: wrap;
  .image {
    width: 20%;
  }
}

/*******************************************************************************
* SERVICES / FABRICATION SUR MESURES
*******************************************************************************/

#blocBackground_fabrication {
    background: #223e7b;
    text-align: center;
    padding: 75px 2%;
    h3 {
      font-weight: bold;
      font-size: $font-size-30;
      color: #fff;
      padding-bottom: 20px;
    }
    p {
      font-weight: normal;
      font-size: $font-size-26;
      color: #fff;
      margin: 0;
    }
}

#blocImages_fabrication {
  display: flex;
  flex-wrap: wrap;
  .image {
    width: 25%;
  }
}

/*******************************************************************************
* PHOTOS / RÉALISATIONS
*******************************************************************************/

#blocIntro_realisations {
  padding: 0 5% 150px 5%;
  margin-top: -125px;
  position: relative;
  h4 {
    font-weight: normal;
    font-size: $font-size-36;
    color: #000;
    padding-bottom: 5px;
  }
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #000;
    padding-bottom: 10px;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}

#blocRealisations {
  padding: 90px 2% 0 2%;
  .realisations.zoom-gallery {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    a.item {
        width: 30%;
      .bloc {
        padding-bottom: 75px;
        .left-side {
          width: 100%;
        }
        .right-side {
          background: #223e7b;
          width: 100%;
          .contenu {
            padding: 50px 5%;
            h3 {
              font-weight: bold;
              font-size: $font-size-36;
              color: #fff;
              padding-bottom: 15px;
            }
            p {
              font-weight: normal;
              font-size: $font-size-24;
              color: #fff;
              line-height: 1.5;
              margin: 0;
              text-align: justify;
              width: 90%;
            }
          }
        }
      }
    }
  }
}

/*******************************************************************************
* PHOTOS / NOS INSTALLATIONS
*******************************************************************************/

#blocInstallation {
  padding: 100px 5% 50px 5%;

  .realisations {
    a.item {
      .bloc {
        display: flex;
        padding-bottom: 85px;
        .contenu {
            padding-left: 3%;
        }
        h3 {
          font-weight: bold;
          font-size: $font-size-36;
          color: #000;
          padding: 25px 0;
        }
        p {
          font-weight: normal;
          font-size: $font-size-24;
          color: #000;
          line-height: 1.3;
          text-align: justify;
        }
      }
    }
  }
}

/*******************************************************************************
* ÉQUIPE
*******************************************************************************/

#blocIntro_equipe {
  padding: 0 5% 50px 5%;
  margin-top: -125px;
  position: relative;
  h4 {
    font-weight: normal;
    font-size: $font-size-36;
    color: #000;
    padding-bottom: 5px;
  }
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #000;
    padding-bottom: 10px;
  }
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    text-align: justify;
    width: 41%;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}

#blocEquipe {
  padding: 50px 5% 50px 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .bloc {
    width: 40%;
    padding-bottom: 25px;
    h4 {
      font-weight: normal;
      font-size: $font-size-36;
      color: #000;
      padding-top: 15px;
    }
    h3 {
      font-weight: bold;
      font-size: $font-size-36;
      color: #000;
      padding: 10px 0;
    }
    p {
      font-weight: normal;
      font-size: $font-size-24;
      color: #000;
    }
  }
}

/*******************************************************************************
* INVENTAIRE
*******************************************************************************/

#blocBackground_inventaire {
  padding: 20px 0;
  background: #223e7b;
  .container-fluid.row {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ul.menu-filter, ul.sous-menu-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 950px;
    margin: 0;
    padding: 0;
    list-style: none;

    li.filter-item {
      line-height: 45px;
      padding: 0 10px;

        & > a {
        padding: 2px;
        font-size: 20px;
        line-height: 1.1;
        white-space: nowrap;
        color: $color-white;
        text-transform: uppercase;
        border-top: 1px solid $color-white;
        border-bottom: 1px solid $color-white;
        transition: .5s;

        &:hover, &.selected {
          border-top: 1px solid $color-blue-lighter;
          border-bottom: 1px solid $color-blue-lighter;
          color: $color-blue-lighter;
          transition: .5s;
        }
      }
    }
  }
  ul.sous-menu-filter {
      background: $color-grey-light;
      padding: 5px;
      margin-top: 15px;
      li.filter-item {
        line-height: 30px;

          & > a {
          padding: 2px;
          font-size: 18px;
          line-height: 1;
          font-weight: 600;
          white-space: nowrap;
          color: $color-blue;
          text-transform: none;
          border-top: none;
          border-bottom: 2px solid $color-blue;
          transition: .5s;

          &:hover, &.selected {
            border-top: none;
            border-bottom: 2px solid $color-blue-lighter;
            color: $color-blue-lighter;
            transition: .5s;
          }
        }
      }
    }
}

#blocInventaire {
    padding: 30px 0;

    .bloc {
        padding: 0;
        margin: auto;
        .grid-item {
            //padding: 0;
            margin: 0;
            padding-bottom: 50px;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #000;
                padding-top: 25px;
            }

            div.clickable-image {
                //border: 2px solid $color-1;
                position: relative;
                overflow: hidden;
                img.photo {
                    transform: scale(1);
                    transition: .3s;
                }
                div.icon-wrapper {
                    position: absolute;
                    max-width: 50%;
                    bottom: 0;
                    right: 0;

                    div.icon-box {
                        z-index: 1;
                        position: relative;
                        img.icon-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            opacity: 0;
                            visibility: hidden;
                            transition: .5s;
                        }
                    }
                }


                &:hover {
                    img.photo {
                        transform: scale(1.1);
                        transition: .3s;
                    }
                    div.icon-wrapper {
                        div.icon-box {
                            img.icon-overlay {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            .info {
                padding-top: 20px;
                display: flex;
                .titre {
                    width: 40%;
                    p {
                        font-weight: 600;
                        font-size: $font-size-20;
                        color: #000;
                        line-height: 1.3;
                    }
                }
                .name {
                    p {
                        font-weight: normal;
                        font-size: $font-size-20;
                        color: #000;
                        line-height: 1.3;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#titre-seul {
  position: absolute;
  top: 74%;
  right: 9%;
  h2 {
    font-size: $font-size-48;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
  }
  h3 {
    font-size: $font-size-36;
    color: #000000;
    font-weight: 600;
    text-transform: initial;
  }
}

#blocIntro_emploi {
  padding: 0 5% 150px 5%;
  margin-top: -125px;
  position: relative;
  h3 {
    font-weight: bold;
    font-size: $font-size-30;
    color: #000;
  }
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    text-align: justify;
    width: 45%;
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}

#blocBackground_emploi {
  background: #223e7b;
  padding: 65px 5% 10% 5%;
  clip-path: polygon(0 0, 100% 0%, 100% 85%, 0 50%);
  margin-top: 100px;
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #fff;
  }
}

#blocEmploi {
  padding: 50px 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .bloc {
    width: 50%;
    padding: 0 8% 100px 4%;
    h3 {
      font-weight: bold;
      font-size: $font-size-36;
      color: #000;
    }
    h4 {
      font-weight: normal;
      font-size: $font-size-36;
      color: #000;
    }
    p {
      font-weight: normal;
      font-size: $font-size-24;
      color: #000;
      line-height: 1.7;
      padding: 10px 0;
    }
    .mybtn.btn-emploi {
      background: #223e7b;
      color: #fff;
      font-weight: normal;
      font-size: $font-size-24;
      padding: 15px 80px;
      border: none;
      &:hover {
        color: #000;
      }
    }
  }
  .section-form {
    .module-formulaire {
      display:none;
      padding: 0 0 50px 0;
      margin-top: -70px;
    }
    h2.formtitre {
      color: #223e7b;
      padding-bottom: 5px;
      font-weight: bold;
    }
    input, textarea {
      width: 100%;
      font-size: 18px;
    }
    .btnFormEmploi {
      clear: both;
      padding-top: 35px;
    }
    .btnFormEmploi .mybtn2 {
      background: #223e7b;
      padding: 20px 50px;
      border: none;
      color: #fff;
      font-size: $font-size-20;
    }
  }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocIntro_coordonnees {
  padding: 0 5% 150px 5%;
  margin-top: -125px;
  position: relative;
  .info {
    display: flex;
    align-items: end;
    .icon {
      padding-right: 25px;
    }
  }
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #000;
  }
  h4 {
    font-weight: 600;
    font-size: $font-size-24;
    color: #000;
    padding-bottom: 10px;
  }
  p {
    font-weight: normal;
    font-size: $font-size-24;
    color: #000;
    margin: 0;
    line-height: 1.3;
    padding-bottom: 4px;
  }
  .contact-heures {
    display: flex;
    padding-top: 100px;
    .icon-titre {
      display: flex;
      align-items: end;
      padding-bottom: 20px;
      .icon {
        padding-right: 25px;
      }
    }
    .contact {
      width: 50%;
      .texte {
        width: 25%;
      }
    }
    .heures {
      width: 50%;
      .jours {
        width: 40%;
      }
    }
    .tel-info, .heures-info {
      display: flex;
      padding-left: 65px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 537px;
    height: 270px;
    background: url("../images/remplissage_trianglebleu.png");
  }
}

#blocTitre {
  padding: 0 6%;
  h3 {
    font-weight: bold;
    font-size: $font-size-36;
    color: #fff;
    background: #223e7b;
    padding: 25px;
  }
}

#texte-formulaire {
    display: flex;
    padding: 100px 7% 50px 7%;
    .texte {
      width: 40%;
      margin-right: 10%;
      padding-top: 3%;
      .titre {
        padding-bottom: 25px;
        h3 {
          font-weight: 600;
          font-size: $font-size-24;
          color: #000;
        }
      }
      p {
        font-weight: normal;
        font-size: $font-size-24;
        color: #000;
        line-height: 1.5;
      }
    }
    .formulaire {
      width: 50%;
      #right {
          overflow: hidden;
          width: 40%;
          position: relative;
          right: -53%;
      }
    }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {

#blocRealisations .bloc .left-side {
    width: 88%;
}
#blocRealisations .bloc .right-side .contenu {
    padding: 45px 10%;
}
}

@media screen and (max-width: 1800px) {

#texte-formulaire .texte .titre h3 br {
  display: none;
}
#blocRealisations .bloc .right-side .contenu h3 {
    font-size: 1.6rem;
}
#blocRealisations .bloc .right-side .contenu p {
    font-size: 1.1rem;
}
}

@media screen and (max-width: 1730px) {

#texte-formulaire .formulaire #right {
    right: -50%;
}
#blocBackground_rhino .bloc-liste .liste ul li br {
  display: none;
}
#bloc50-50 .right-side {
    width: 45%;
}
#blocRealisations .bloc .left-side {
    width: 90%;
}
#blocBackground_rampes .logo {
    position: initial;
    padding-top: 25px;
}
}

@media screen and (max-width: 1675px) {

#titre {
    top: 68%;
    right: 5%;
}
#blocRealisations .bloc .right-side .contenu {
    padding: 38px 10%;
}
}

@media screen and (max-width: 1650px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu {
    background: #fff;
    bottom: 20%;
    padding-right: 3%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu-top .logo {
    flex-direction: initial;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu-top .item-menu-top {
    padding-right: 9.5%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu-top .logo .icon {
    padding-right: 0;
    margin-left: 0;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu-top .logo .lang {
    margin-left: 10px;
}
#texte-formulaire .formulaire #right {
    right: -48%;
}
#blocRealisations .bloc .left-side {
    width: 100%;
}
#blocRealisations .bloc .right-side .contenu {
    padding: 40px 8%;
}
#blocIntro::before {
  display: none;
}
#titre {
    top: 68%;
    right: 10%;
}
#blocIntro p {
    width: 55%;
}
#bloc50-50 .left-side {
    width: 50%;
    margin-right: 90px;
}
#bloc50-50 .right-side {
    width: 50%;
}
#blocBackground_equipements .titre {
    width: 34%;
}
#blocRealisations .bloc .right-side .contenu h3 {
    font-size: 1.4rem;
}
#blocRealisations .bloc .right-side .contenu p {
    font-size: 1rem;
}
}

@media screen and (max-width: 1600px) {

#titre-seul {
    top: 67%;
    right: 9%;
}
#titre {
    top: 65%;
    right: 5%;
}
}

@media screen and (max-width: 1560px) {

#blocIntro p {
    width: 56%;
}
#blocBackground_rhino .bloc-liste .liste ul li {
    font-size: 1.3rem;
    line-height: 1.3;
}
#bloc50-50_attaches {
    padding: 55px 5% 85px 5%;
}
#blocIntro_attaches {
    padding: 50px 5%;
}
#blocIntro_attaches p {
    width: 50%;
}
#blocInstallation .realisations a.item .bloc h3 {
    padding: 15px 0 0px 0;
}
#blocIntro_attaches .logo {
    left: 50%;
}
#titre {
    top: 75%;
}
#blocIntro_attaches::before {
  display: none;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 40%;
}
}

@media screen and (max-width: 1535px) {

#blocIntro_coordonnees .contact-heures .contact {
    width: 45%;
}
#blocIntro_coordonnees .contact-heures .contact .texte {
    width: 30%;
}
#texte-formulaire .formulaire #right {
    right: -46%;
}
#blocInstallation {
    padding: 0 5%;
}
#blocInstallation .bloc h3 {
    padding: 15px 0 10px 0;
}
#blocBackground_location .bloc-liste .liste {
    width: 40%;
}
#blocBackground_location ul li {
    font-size: 1.2rem;
}
#titre {
    top: 63%;
    right: 10%;
}
#blocBackground_attaches p br {
  display: none;
}
#blocImages_remorques_attaches {
    margin-bottom: -65px;
}
#blocBackground_equipements {
    padding: 85px 0 90px 2%;
}
#blocBackground_equipements .titre {
    width: 33%;
}
#blocIntro_attaches .logo {
    left: 55%;
}
}

@media screen and (max-width: 1490px) {

#blocIntro_coordonnees .contact-heures .heures .jours {
    width: 55%;
}
#blocBackground_emploi {
    padding: 65px 5% 15% 5%;
}
#titre {
    top: 61%;
    right: 3%;
}
}

@media screen and (max-width: 1460px) {

#blocRealisations .bloc .right-side .contenu {
    padding: 40px 8% 0 8%;
}
#blocRealisations .bloc .right-side .contenu p {
    font-size: 0.9rem;
}
}

@media screen and (max-width: 1430px) {

footer::before {
  display: none;
}
#blocBurst a.bloc.side, #blocBurst_2 a.bloc.side {
    padding-top: 100px;
}
#blocBurst .bloc, #blocBurst_2 .bloc {
    width: 30%;
}
#bloc50-50_reparation .right-side p {
    width: 90%;
}
#blocIntro p {
    width: 65%;
}
#titre {
    top: 59%;
    right: 10%;
}
#titre h2 {
    font-size: 2.2rem;
}
#titre h3 {
    font-size: 1.5rem;
}
#titre-seul {
    top: 60%;
    right: 9%;
}
#blocBackground_fabrication p br {
  display: none;
}
#blocBackground .bloc-liste .liste ul li {
    font-size: 1.3rem;
    line-height: 1.3;
}
#blocBackground_equipements {
    background: #223e7b;
}
#blocBackground_equipements {
    padding: 85px 5% 90px 5%;
}
#blocBackground_equipements .titre {
    width: 60%;
}
#blocBackground_inventaire ul.menu-filter li {
    font-size: 1.1rem;
}
#blocInstallation .realisations a.item .bloc .contenu {
    padding-top: 2%;
}
}

@media screen and (max-width: 1360px) {

#blocIntro_emploi::before {
  display: none;
}
#blocBackground_emploi {
    margin-top: 0px;
}
#blocIntro_emploi {
    padding: 0 5% 100px 5%;
}
#blocIntro_emploi {
    padding: 100px 5%;
}
}

@media screen and (max-width: 1330px) {

#titre {
    top: 55%;
    right: 10%;
}
#blocIntro {
    padding: 60px 5% 0 5%;
}
}

@media screen and (max-width: $size-md-max) {

nav.module-menu .menu-navbar {
    height: 160px;
}
#amazingcarousel-container-2 {
    padding: 0 !important;
}
#texte-formulaire .formulaire #right {
    right: -43%;
}
#blocBackground_inventaire {
    margin-top: 0;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li .submenu {
    top: 83.5px;
}
nav.module-menu .menu-navbar.nav-invisible {
    top: -145px;
}
#bloc50-50_reparation {
    padding: 100px 5% 75px 5%;
}
#blocImages_reparation-2 .images {
    width: 33%;
}
#blocBurst_2 {
    padding: 50px 2% 135px 2%;
}
#blocImages_location {
    padding-bottom: 60px;
}
#blocImages_remorques_attaches {
    margin-bottom: 0;
}
#titre {
    top: 51%;
    right: 10%;
}
#titre-seul {
    top: 52%;
    right: 9%;
}
#blocIntro {
    padding: 70px 5% 0 5%;
}
#blocBackground_rhino {
    margin-bottom: -55px;
}
#bloc50-50 .right-side p {
    width: 100%;
}
#bloc50-50 .left-side {
    width: 61%;
    margin-right: 65px;
}
#blocBackground_rampes ul li {
    font-size: 1.1rem;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 45%;
}
#blocInstallation .realisations a.item .bloc {
    flex-direction: column;
    width: 60%;
    margin: auto;
}
#blocInstallation .realisations a.item .bloc .contenu {
    padding-left: 0;
    padding-top: 0;
}
}

@media screen and (max-width: 1260px) {

#blocIntro_coordonnees::before {
  display: none;
}
#blocIntro_coordonnees {
    padding: 0 2% 150px 5%;
}
#blocIntro_coordonnees .contact-heures .heures .jours {
    width: 75%;
}
#right .btn {
    padding: 15px 55px;
}
#titre {
    top: 52%;
    right: 10%;
}
#titre-seul {
    top: 54%;
    right: 7%;
}
#blocBackground_inventaire ul.menu-filter li {
    font-size: 1rem;
}
}

@media screen and (max-width: 1200px) {

#blocIntro_attaches p {
    width: 80%;
}
#titre {
    top: 50%;
    right: 10%;
}
#blocIntro_attaches {
    padding: 100px 5% 50px 5%;
    flex-direction: column-reverse;
    align-items: baseline;
}
#blocIntro_attaches .logo {
    position: initial;
}
#blocIntro_rhino::before {
  display: none
}
#blocIntro_rhino p {
    width: 75%;
}
#blocIntro_rhino {
    padding: 75px 5%;
}
}

@media screen and (max-width: 1180px) {

#blocRealisations .bloc .right-side .contenu {
    padding: 20px 8% 0 8%;
}
}

@media screen and (max-width: 1150px) {

#titre-seul {
    top: 50%;
    right: 7%;
}
#blocIntro_coordonnees {
    padding: 100px 2% 115px 5%;
}
#titre {
    top: 50%;
    right: 4%;
}
#blocIntro_realisations::before {
  display: none;
}
#blocIntro_realisations {
    padding: 50px 5% 0 5%;
}
#bloc50-50 {
    padding: 100px 5%;
}
#blocBackground_rampes {
    padding: 50px 5%;
    clip-path: none;
}
#blocBackground_inventaire ul.menu-filter li {
    padding: 0 15px;
}
}

@media screen and (max-width: 1115px) {

footer #blocFooter .menu a {
    padding-right: 30px;
}
#right .btn {
    padding: 15px 40px;
}
#blocIntro_equipe::before {
  display: none;
}
#blocIntro_equipe p {
    width: 75%;
}
#blocRealisations .bloc .right-side .contenu h3 {
    font-size: 1.3rem;
    padding-bottom: 10px;
}
#blocRealisations .bloc .right-side .contenu {
    padding: 17px 8% 0 8%;
}
}

@media screen and (max-width: 1095px) {

#blocIntro_coordonnees .contact-heures {
    flex-direction: column;
    padding-top: 50px;
}
#blocIntro_coordonnees .contact-heures .contact {
    width: 100%;
    padding-bottom: 50px;
}
#blocIntro_coordonnees .contact-heures .heures {
    width: 100%;
}
#blocIntro_coordonnees .contact-heures .heures .jours {
    width: 30%;
}
#blocIntro_realisations {
    padding: 0 5%;
}
#blocIntro_coordonnees {
    padding: 100px 2% 85px 5%;
}
#blocRealisations .bloc {
    flex-direction: column;
}
#blocRealisations .bloc .left-side, #blocRealisations .bloc .right-side {
    width: 58%;
    margin: auto;
}
#blocRealisations .bloc .right-side .contenu {
    padding: 50px 8%;
}
#blocRealisations .bloc .right-side .contenu p {
    font-size: 1rem;
}
#blocRealisations .bloc .right-side .contenu h3 {
    font-size: 1.5rem;
    padding-bottom: 15px;
}
}

@media screen and (max-width: 1065px) {

#blocIntro_emploi p {
    width: 60%;
}
#titre {
    top: 46%;
    right: 3%;
}
#blocBackground_emploi h3 {
    font-size: 1.7rem;
}
#blocBackground_rhino {
    padding: 65px 5%;
    clip-path: none;
    margin-bottom: 0;
}
#blocBackground_rhino .bloc-liste .liste ul li {
    font-size: 1.1rem;
}
footer.remplissage #blocFooter .menu a {
    padding-right: 25px;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 65%;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar .wrapper.left {
    display: block;
}
nav.module-menu .menu-navbar {
    height: 75px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 275px;
}
nav.module-menu {
    padding-top: 75px;
}
nav.module-menu #menu-mobile ul li.lang > a div, nav.module-menu #menu-mobile ul li.phone > a, nav.module-menu #menu-mobile ul li > .menu-title, nav.module-menu #menu-mobile ul li .submenu ul li.subitem a div, nav.module-menu #menu-mobile ul li > a {
    color: #000;
}
#bloc50-50_accueil::before {
  display: none;
}
nav.module-menu.scrolled .menu-navbar.shadow.nav-visible {
    background: #fff;
}
#texte-formulaire {
    padding: 50px 7%;
}
#right .btn {
    padding: 15px 30px;
}
#blocIntro_emploi p {
    width: 100%;
}
#blocIntro_emploi {
    padding: 65px 5%;
}
#blocBackground_emploi {
    padding: 50px 5%;
    clip-path: none;
}
#blocIntro_equipe p {
    width: 80%;
}
#bloc50-50_reparation .left-side {
    width: 50%;
}
#titre {
    top: 50%;
    right: 2%;
}
#blocIntro_realisations {
    padding: 100px 5% 50px 5%;
}
#blocIntro_rhino {
    padding: 125px 5% 75px 5%;
}
#blocIntro_rhino p {
    width: 85%;
}
#bloc50-50_reparation .right-side p {
    width: 100%;
}
#bloc50-50 {
    padding: 50px 5%;
}
#blocBackground {
    padding: 65px 5%;
    clip-path: none;
    margin-bottom: 0;
}
#blocBackground .bloc-liste .liste ul li {
    font-size: 1.1rem;
}
#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .left-side {
    width: 85%;
    margin-right: 0;
}
#bloc50-50 .right-side {
    width: 85%;
    padding-top: 25px;
}
#blocBackground_inventaire ul.menu-filter li {
    font-size: 1.3rem;
}
}

@media screen and (max-width: $size-sm-max) {

footer #blocFooter .menu a {
    padding-right: 15px;
}
footer #blocFooter {
    margin-left: 21%;
    padding-top: 10%;
}
footer {
    height: 400px;
}
#blocLogo {
    padding: 50px 0;
}
#bloc50-50_accueil {
    flex-direction: column;
}
#bloc50-50_accueil .left-side {
    width: 100%;
    padding-top: 0;
}
#bloc50-50_accueil .right-side {
    width: 50%;
    margin: auto;
    padding-bottom: 55px;
    padding-top: 25px;
}
#blocImages .image {
    width: 50%;
}
#blocBurst a.bloc.side, #blocBurst_2 a.bloc.side {
    padding-top: 60px;
}
#blocBurst, #blocBurst_2 {
    padding: 100px 2%;
    background: #223e7b;
}
#texte-formulaire {
    flex-direction: column;
    padding: 50px 7%;
}
#texte-formulaire .texte {
    width: 100%;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 30px;
}
#texte-formulaire .formulaire {
    width: 100%;
}
#texte-formulaire .formulaire #right {
    position: initial;
}
#right .btn {
    padding: 15px 60px;
}
#blocIntro_emploi {
    padding: 150px 5% 30px 5%;
}
#blocRealisations .bloc .left-side, #blocRealisations .bloc .right-side {
    width: 75%;
    margin: auto;
}
#bloc50-50_reparation {
    padding: 50px 5% 75px 5%;
    flex-direction: column;
}
#bloc50-50_reparation .left-side {
    width: 100%;
    margin-right: 0;
}
#bloc50-50_reparation .right-side {
    width: 100%;
    padding-top: 35px;
}
#blocBackground_reparation {
    padding: 150px 9% 145px 9%;
    background-position: center;
}
#blocImages_reparation .images {
    width: 50%;
    padding-bottom: 25px;
}
#blocImages_reparation-2 .images {
    width: 50%;
}
#blocImages_location .image, #blocImages_location .blocGris {
    width: 33.3333%;
}
#blocImages_location {
    padding-bottom: 0;
}
#blocBackground_location {
    padding: 55px 5%;
    clip-path: none;
    margin-top: 5%;
}
#blocBackground_location .bloc-liste {
    flex-direction: column;
}
#blocBackground_location .bloc-liste .liste {
    width: 100%;
}
#blocBackground_location ul li {
    font-size: 1.3rem;
}
footer.remplissage #blocFooter {
    margin-left: 0%;
    padding-top: 14%;
    margin-right: 45px;
    width: 100%;
}
footer.remplissage #blocFooter .menu a {
    padding-right: 15px;
}
#titre, #titre-seul {
    position: initial;
    text-align: center;
    margin-top: -70px;
    padding-bottom: 100px;
}
#titre h2, #titre-seul h2 {
    font-size: 2.4rem;
}
#titre h3 {
    font-size: 1.8rem;
}
#blocIntro_realisations {
    padding: 125px 5% 0px 5%;
}
#blocBackground_rhino {
    margin-bottom: 50px;
    margin-top: 0;
}
#blocImages_rhino .image {
    width: 50%;
}
#blocImages_fabrication .image {
    width: 50%;
}
#bloc50-50 .left-side, #bloc50-50 .right-side {
    width: 100%;
}
#bloc50-50 {
    padding: 65px 5%;
}
#blocImages_remorques .images, #blocImages_remorques-2 .images {
    width: 33%;
}
#bloc50-50_attaches {
    flex-direction: column;
}
#bloc50-50_attaches .left-side {
    width: 85%;
    margin-right: 0;
}
#bloc50-50_attaches .right-side {
    width: 85%;
    padding-top: 55px;
}
#blocBackground_equipements .blocLogo .logo.first {
    margin-right: 0;
}
#blocBackground_equipements {
    padding: 85px 2% 90px 2%;
}
#blocBackground_equipements .titre {
    width: 60%;
    margin-right: 55px;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 86%;
}
#blocInstallation .realisations a.item .bloc {
    width: 90%;
}
}

@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 230px;
}
#blocLogo {
    padding: 35px 0;
}
#blocBurst, #blocBurst-2 {
    background-position: left;
}
footer #blocFooter .menu, footer #blocFooter .section-copyright {
    flex-direction: column;
}
footer {
    height: 600px;
}
footer #blocFooter {
    padding-top: 35%;
    margin-left: 5%;
}
footer #blocFooter .menu a {
    padding-right: 0;
    padding-bottom: 15px;
}
footer #blocFooter .section-copyright {
    padding-top: 35px;
}
footer #blocFooter .menu {
    padding-bottom: 20px;
}
footer #blocFooter .section-copyright .mediaweb {
    padding: 30px 0;
}
#bloc50-50_accueil .right-side {
    width: 60%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 0;
}
#blocLogo {
    margin-bottom: -65px;
}
#bloc50-50_accueil .right-side .main-content .owl-theme .owl-item img {
    width: 100%;
    height: 100%;
}
#bloc50-50_accueil .right-side {
    margin: 0 auto;
    padding-top: 60px;
}
#blocBurst a.bloc, #blocBurst_2 a.bloc {
    padding-top: 0;
    width: 65%;
    margin: auto;
}
#blocBurst, #blocBurst_2 {
    flex-direction: column;
    padding: 50px 2% 0 2%;
    margin-bottom: 0;
}
#blocIntro_coordonnees {
    padding: 0 5% 75px 5%;
    margin-top: 0;
    margin-bottom: 0;
}
#blocIntro_coordonnees .contact-heures .tel-info, #blocIntro_coordonnees .contact-heures .heures-info {
    padding-left: 0;
}
#blocIntro_coordonnees .contact-heures .contact .texte {
    width: 35%;
}
#blocIntro_coordonnees .contact-heures .heures .jours {
    width: 75%;
}
#texte-formulaire .formulaire #right {
    width: 100%;
}
#blocEmploi .bloc {
    width: 100%;
    padding-bottom: 85px;
}
#blocEmploi {
    padding: 50px 5% 0 5%;
    margin-bottom: -60px;
}
#blocInventaire .bloc {
    max-width: 550px;
    margin: auto;
}
#blocIntro_equipe {
    padding: 0 5% 50px 5%;
    margin-top: 0;
}
#blocEquipe .bloc {
    width: 100%;
    padding-bottom: 60px;
}
#blocIntro_equipe p {
    width: 100%;
}
#blocIntro_attaches {
    padding: 0 5%;
    margin-top: 0;
}
#blocIntro_attaches p {
    width: 100%;
    line-height: 1.5;
}
#blocInstallation .bloc {
    width: 100%;
    padding-bottom: 65px;
}
#blocInstallation {
    margin-bottom: -65px;
}
#blocIntro_realisations {
    padding: 35px 5% 0 5%;
    margin-top: 0;
}
#blocRealisations .bloc .left-side, #blocRealisations .bloc .right-side {
    width: 100%;
    margin: auto;
}
#blocRealisations {
    padding: 50px 0 0 0;
    margin-bottom: -65px;
}
#blocBurst_2 a.bloc.side {
    padding: 60px 0;
}
footer.remplissage {
    background: #bab9ba;
    height: 465px;
}
footer.remplissage #blocFooter .menu {
    padding-bottom: 25px;
}
footer.remplissage #blocFooter .section-copyright {
    padding-top: 25px;
}
footer.remplissage #blocFooter {
    padding-top: 3%;
    margin-left: 10%;
}
#blocIntro {
    margin-top: 0;
    padding: 15px 5% 20px 5%;
}
#blocIntro p {
    width: 100%;
}
#blocImages_location .image, #blocImages_location .blocGris {
    width: 50%;
}
#titre, #titre-seul {
    margin-top: -35px;
    padding-bottom: 75px;
}
#blocIntro {
    padding: 0 5%;
}
#bloc50-50_reparation {
    padding: 0px 5% 75px 5%;
}
#blocBackground_rhino .bloc-liste {
    flex-direction: column;
}
#blocBackground_rhino .bloc-liste .liste {
    width: 100%;
}
#blocBackground_rhino .bloc-liste .liste ul li {
    font-size: 1.5rem;
    padding-bottom: 20px;
}
#blocIntro_rhino {
    padding: 125px 5% 35px 5%;
}
#blocBackground .bloc-liste {
    flex-direction: column;
}
#blocBackground .bloc-liste .liste {
    width: 100%;
}
#blocBackground .bloc-liste .liste ul li {
    font-size: 1.4rem;
}
#blocImages_remorques .images, #blocImages_remorques-2 .images {
    width: 50%;
}
#blocBackground_attaches {
    padding: 75px 5%;
    margin-top: 30px;
}
#bloc50-50_attaches .left-side {
    width: 100%;
}
#bloc50-50_attaches .right-side {
    width: 100%;
}
#blocImages_remorques_attaches {
    margin-bottom: 0;
    padding: 0 2%;
}
#blocImages_remorques_attaches .images {
    width: 50%;
}
#blocBackground_equipements {
    padding: 50px 5%;
    flex-direction: column;
    margin-top: 50px;
}
#blocBackground_equipements .blocLogo {
    flex-direction: column;
}
#blocBackground_equipements .titre {
    width: 100%;
    padding-bottom: 45px;
    margin: 0;
}
#blocImages_remorques_equipements .images {
    width: 50%;
}
#blocImages_rampes .image {
    width: 50%;
}
#blocBackground_rampes ul li {
    font-size: 1.2rem;
    padding-bottom: 15px;
}
#blocInstallation .realisations a.item {
    width: 100%;
    padding-bottom: 0px;
}
#blocInstallation {
    padding: 45px 5% 0 5%;
}
#blocBackground_inventaire ul.menu-filter li {
    font-size: 1.2rem;
}
#blocRealisations .realisations.zoom-gallery a.item {
    width: 100%;
}
#blocRealisations .realisations.zoom-gallery a.item .bloc .right-side .contenu {
    padding: 30px 5%;
}
#blocInstallation .realisations a.item .bloc {
    width: 100%;
}
}

@media screen and (max-width: 360px) {

#blocIntro_coordonnees .contact-heures .heures .jours {
    width: 100%;
}
#blocIntro_emploi h3 {
    padding-bottom: 5px;
}
#blocIntro_emploi p {
    line-height: 1.5;
}
#blocIntro_emploi p {
    width: 85%;
}
#titre {
    margin-top: -20px;
    padding-bottom: 50px;
}
}
